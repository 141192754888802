export class SiteRedirector {
    public static redirectToSite(region) {
        // Request desired redirect url from backend (region and host environment specific)
        var request = new Request(`/api/config/siteurl/${region}`, {
            headers: { 'Content-Type': 'application/json' }
        });
        fetch(request)
            .then((response) => response.json())
            .then((url) => {
                if (url == '') {
                    throw new Error(`Could not resolve redirect for region: "${region}"`)
                }
                window.location.assign(url + '?setregion'); // redirect to desired url.. Also sets the "setregion" parameter
            })
            .catch((error) => console.error(error));
    }
}